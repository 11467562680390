<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="평가기간"
            name="assessmentDates"
            v-model="searchParam.assessmentDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="RAM_ASSESS_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramAssessTypeCd"
            label="평가구분"
            v-model="searchParam.ramAssessTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="stepItems"
            type="none"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ramStepCd"
            label="진행단계"
            v-model="searchParam.ramStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="RAM_TECHNIQUE_CD"
            itemText="codeName"
            itemValue="code"
            label="평가기법"
            name="ramTechniqueCd"
            v-model="searchParam.ramTechniqueCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.ramStepCd)]">
                    {{ props.row.ramStepName }}
                  </div>
                  <div :class="['text-grid-etc', 'txt-box-grid', 'text-orange-box']">
                    {{ props.row.ramTechniqueName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.assessmentName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.assessmentDate }} | {{props.row.ramAssessTypeName}}
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskList',
  data() {
    return {
      searchParam: {
        plantCd: null,
        assessmentDates: [],
        ramAssessTypeCd: null,
        ramStepCd: 'RRS0000010',
        ramTechniqueCd: null,
        isVendor: false,
        approvalUserId: '',
      },
      grid: {
        columns: [
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ramStepName',
            field: 'ramStepName',
            label: '진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'assessmentDate',
            field: 'assessmentDate',
            label: '평가기간',
            align: 'center',
            style: 'width:150px',
            sortable: true
          },
          {
            name: 'ramAssessTypeName',
            field: 'ramAssessTypeName',
            label: '평가구분',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
        ],
        data: [],
      },
      stepItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.assessPlan.list.url
      // code setting
      this.$comm.getStepItems('RAM_RISK_STEP_CD').then(_result => {
        _result.splice(0, 1);
        _result.splice(0, 1);
        this.stepItems = _result;
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row) {
      this.popupOptions.title = '위험성평가 상세'; // 위험성평가 상세
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: row.ramRiskAssessmentPlanId,
        vendorFlag: false,
      };
      this.popupOptions.target = () => import(`${'./riskDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'RRS0000001': // 평가계획
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'RRS0000010': // 평가실행 & 감소대책 계획 및 실행
          cls = 'txt-box-grid text-light-blue-box';
          break;
        case 'RRS0000015': // 평가완료
          cls = 'txt-box-grid text-positive-box';
          break;
      }
      return cls;
    },
  }
};
</script>
